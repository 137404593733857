.dashboard-card-scroll {
  .dashboard-card-scroll-container {
    max-width: 350px;
    margin: auto;
    border: solid 4px #fff;
    border-radius: 24px;
    padding: 1.5rem 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .one-card-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .login-btn {
    width: 100%;
    max-width: 320px;
  }
}
.dashboard-card-scroll--buy-now {
  text-align: center;
  button {
    width: 100%;
    max-width: 320px;
  }
}
