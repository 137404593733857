$navbar-breakpoint: 991.98px;
.z-nav {
  background-color: $primary-color;
  display: flex;
  justify-content: space-around;
  height: 100px;
  padding: 1rem 0rem;
  width: 100%;
  position: relative;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  border-bottom: 2px solid $background-color;
  .z-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: #ffffff;
    font-weight: 600;
  }
  .logo-img {
    max-width: 300px;
    height: auto;
    object-fit: cover;
    cursor: pointer;
  }
  .z-nav-pages {
    display: flex;
    align-items: center;
  }
  .z-nav-page {
    padding-right: 1.5rem;
  }
  button,
  .link {
    font-size: 1.3rem;
  }
  .link {
    color: #ffffff;
    cursor: pointer;
    transition: $transition;
    font-weight: 600;
    &:hover {
      color: $button-color;
    }
  }
  button {
    font-weight: unset;
    width: 140px;
  }
  .z-burger {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: $transition;
    .line {
      width: 25px;
      height: 3px;
      background-color: #ffffff;
      margin: 2.5px;
      transition: all 0.3s ease;
    }
    &:hover {
      .line {
        background-color: #333 !important;
      }
    }
    &.forwards {
      // .line {
      //   // background-color: #333 !important;
      // }
      .line1 {
        transform: rotate(-45deg) translate(-5px, 6px);
      }
      .line2 {
        opacity: 0;
      }
      .line3 {
        transform: rotate(45deg) translate(-5px, -6px);
      }
    }
  }

  @media (max-width: $navbar-breakpoint) {
    .navbar--dashboard-navbar-drop {
      padding-bottom: 1.5rem;
      .dropdown-menu {
        display: none;
      }
      .navbar-drop-btn {
        i {
          display: none;
        }
      }
    }
    .z-nav-pages {
      background-color: $background-color;
      opacity: 0.95;
      display: flex;
      position: absolute;
      right: 0px;
      min-width: 300px;
      max-width: 800px;
      top: 100px;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      transform: translateX(100%);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
        0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
      z-index: 1000;

      &.forwards {
        animation: navSlideIn 0.65s ease forwards;
      }
      &.backwards {
        animation: navSlideOut 0.65s ease backwards;
      }
    }
    .z-nav-page {
      padding: 1.2rem 0rem;
      padding-right: 0rem;
    }
    .link {
      color: #333;
    }
    .z-burger {
      display: flex;
    }
  }
}

@keyframes navSlideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes navSlideOut {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}

@media (max-width: $navbar-breakpoint) {
  .mobile-overflow-wrapper {
    overflow-x: hidden;
  }
}
