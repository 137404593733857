.game-arena {
  .nordic-logo-img {
    width: 180px;
    height: auto;
  }
  .game-name {
    border: 4px #fff solid;
    border-radius: 24px;
    padding: 0.25rem 0.25rem;
    width: 180px;
    margin-left: auto;
    text-align: center;
    font-weight: 600;
    color: #fff;
  }

  .oppenent-name {
    border: 2px #fff solid;
    border-radius: 8px;
    padding: 0.5rem 0.25rem;

    text-align: center;
    font-weight: 600;
    color: #fff;
  }
  .rules-container {
    border: 2px #fff solid;
    border-radius: 8px;
    padding: 0.5rem 0.25rem;

    width: 100%;
    text-align: center;
    color: #fff;
    transition: $transition;
    cursor: pointer;
    &:hover {
      color: $primary-color;
      border: 2px $primary-color solid;
    }
  }
  .go-game {
    height: 180px;
    width: 100%;
    border: 2px #fff solid;
    border-radius: 18px;
    color: #fff;
    cursor: not-allowed;
    &.activce {
      color: #fff;
      text-shadow: 0 0 3px $card-green, 0 0 7px $card-green;
      cursor: pointer;
      border: 4px solid $card-green;
      box-shadow: rgba($card-green, 0.48) 6px 2px 16px 0px, rgba($card-green, 0.8) -6px -2px 16px 0px;
    }
    &.go-game-move {
      cursor: pointer;
    }
  }
  .game-arena--leave {
    border: 4px #fff solid;
    border-radius: 24px;
    padding: 0.25rem 0.25rem;

    margin-left: auto;
    text-align: center;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    transition: $transition;
    &:hover {
      color: $red-color;
      border: 4px $red-color solid;
    }
  }
}
