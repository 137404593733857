$user-drop-width: 250px;
.user-drop {
  display: inline-block;
  align-items: center;
  z-index: 100;

  cursor: pointer;
  .drop-btn {
    width: $user-drop-width;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    border: #fff 3px solid;
    border-radius: 8px;
    padding: 0.4rem 0.8rem;
    color: #fff;
    font-family: $font-family-1;
    z-index: 100;
    transition: $transition;
    &:hover {
      color: $card-green;
    }
  }
  .dropdown-menu {
    width: $user-drop-width;
    position: absolute;
    text-align: start;
    background-color: $background-color-1;
    border-radius: 4px;
    z-index: 100;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    li {
      cursor: pointer;
      transition: $transition;
    }
    &.clicked {
      display: none;
    }

    .dropdown-link {
      display: block;
      height: 100%;
      width: 100%;
      color: #fff;
      cursor: pointer;
      padding: 15px 10px;
      font-size: 1.1rem;

      &:hover {
        background: darken($button-color, 10%);
      }
    }
  }
}

.user-drop-coin-container {
  text-align: center;
  width: 250px;
  height: 120px;
  position: relative;
  &.is-dropdown {
    z-index: -10;
  }
}

.icon-left,
.icon-right {
  position: absolute;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  transition: $transition;
  z-index: 12;
  &:hover {
    color: $primary-color;
  }
}
.icon-left {
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
}
.icon-right {
  top: 50%;
  right: 0%;
  transform: translateY(-50%);
}
.user-circle-container {
  position: relative;
  width: inherit;
  height: inherit;
  margin: 0 auto;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    left: 68px;
    top: 2px;
    border-radius: 100%;
    width: 115px;
    height: 115px;
    box-shadow: 0px 0px 0px 2000px #262a3b;
    pointer-events: none;
  }
}
.user-drop-coin-video {
  width: 120x;
  height: 120px;
  z-index: 1;
  cursor: pointer;
}

.user-circle-container-nl-coin {
  position: relative;
  width: inherit;
  height: inherit;
  margin: 0 auto;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    left: 68px;
    top: 2px;
    border-radius: 100%;
    width: 115px;
    height: 115px;
    box-shadow: 0px 0px 0px 2000px #262a3b;
    pointer-events: none;
  }
}
.user-drop-coin-video-nl-coin {
  width: 142x;
  height: 142px;
  z-index: 1;
  transform: translateY(-11px);
  cursor: pointer;
}

.user-drop-coin-title {
  margin: auto;
  .coin-title {
    font-size: 12px;
  }
}
