.game-outcome {
  min-height: 400px;
  .points-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .img-property-extra {
    width: 80px;
    height: auto;
  }
}
