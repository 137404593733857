.dashboard-layout {
  min-height: 100vh;
  padding-top: 3rem;
  padding-bottom: 12rem;
  .dashboard-layout-container {
    max-width: 1800px;
    margin: auto;
    padding: 0 16px;
  }
  .dashboard-layout--col {
    position: unset;
  }
}
