.game-final-statistic {
  max-width: 1200px;
  margin: auto;
  .right-col-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }
  .end-result {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .winner-coin{
    text-align: center;
    width: 250px;
    height: 120px;
    position: relative;
  }

  .final-container-nl-coin {
    position: relative;
    width: inherit;
    height: inherit;
    margin: 0 auto;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      left: 68px;
      top: 2px;
      border-radius: 100%;
      width: 115px;
      height: 115px;
      box-shadow: 0px 0px 0px 2000px #262a3b;
      pointer-events: none;
    }
  }
  .final-coin-video-nl-coin {
    width: 142x;
    height: 142px;
    z-index: 1;
    transform: translateY(-11px);
  }
}
