.checkbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  .round {
    position: relative;

    label {
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 50%;
      cursor: pointer;
      height: 22px;
      width: 22px;
      left: 0;
      position: absolute;
      top: 2px;
    }

    label:after {
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      content: "";
      height: 6px;
      left: 4px;
      opacity: 0;
      position: absolute;
      top: 4px;
      transform: rotate(-45deg);
      width: 12px;
    }

    input {
      visibility: hidden;
    }

    input:checked + label {
      background-color: $primary-color;
      border-color: $primary-color;
    }
    input.error + label {
      border-color: $red-color;
    }
    input.error:checked + label {
      background-color: $primary-color;
      border-color: $primary-color;
    }
    input:checked + label:after {
      opacity: 1;
    }
  }
}
