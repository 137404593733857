.card-wallet {
  width: 220px;
  margin: auto;
  .video-container {
    height: 350px;
    width: 220px;
  }
  .card-video {
    width: 100%;
    height: 350px;
    object-fit: scale-down;
    cursor: pointer;
    &.not-loaded {
      display: none;
    }
  }
  .number {
    cursor: pointer;
    &.active {
      border-bottom: 2px solid $primary-color;
    }
  }
}
