.game-rules {
  .content-text {
    padding: 2rem 12px;
  }
  .one-round {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .img-container {
    width: 150px;
  }
  .img-property {
    width: 75px;
    height: auto;
  }
}
