.game-final {
  .next-round {
    border: 4px #fff solid;
    border-radius: 24px;
    padding: 0.5rem 0.75rem;
    width: 100%;
    max-width: 400px;
    margin: auto;
    text-align: center;
    font-weight: 600;
    color: #fff;
    transition: $transition;
    cursor: pointer;
    &:hover {
      border: 4px $card-green solid;
      color: $card-green;
    }
  }
  .oppenent-name {
    border: 2px #fff solid;
    border-radius: 8px;
    padding: 0.5rem 0.25rem;

    max-width: 325px;
    margin: auto;
    text-align: center;
    font-weight: 600;
    color: #fff;
  }
}
