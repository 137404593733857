.tippy {
  .tippy-box {
    background: #000000;
    color: #fff;
    border-radius: 4px;
    font-size: 0.9rem;
    padding: 6px 4px;
    max-width: 180px;
    font-size: 0.9rem;
    text-align: center;
  }
  &.tippy-hover-points {
    .tippy-box {
      max-width: 300px;
    }
  }
  .hover-element {
    cursor: pointer;
  }
  &.tippy-game-property {
    .tippy-box {
      max-width: 300px;
    }
  }
}
