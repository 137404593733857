.dashboard-game-strip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .game-name {
    border: 4px #fff solid;
    border-radius: 24px;
    padding: 0.5rem 0.25rem;
    width: 20%;
    text-align: center;
    font-weight: 600;
    transition: $transition;
  }
  .game-1 {
    cursor: pointer;
    transition: $transition;
    &.active {
      background-color: #fff;
      color: #333;
    }
    &:hover {
      color: $primary-color;
    }
  }
  .game-fame {
    width: 180px;
  }
  .game-enter {
    border: 4px #fff solid;
    border-radius: 24px;
    padding: 0.5rem 0.25rem;
    width: 50%;
    text-align: center;
    cursor: not-allowed;
    font-weight: 600;
    transition: $transition;
    &.ready {
      cursor: pointer;
      background-color: $primary-color;
      // border: solid 4px #ffd700;
      box-shadow: rgba($primary-color, 0.48) 6px 2px 16px 0px, rgba($primary-color, 0.8) -6px -2px 16px 0px;
      &:hover {
        // color: $card-green;
        background-color: darken($primary-color, 10%);
        // border: 4px solid $card-green;
      }
    }
  }
}
