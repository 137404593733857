.card-scroll {
  margin: auto;
  display: flex;
  .video-container {
    width: 150px;
    height: 180px;
    text-align: center;
  }

  .card-video {
    width: 100px;
    height: 170px;
    &.blur {
      filter: blur(6px);
      -o-filter: blur(6px);
    }
    &.grabbing {
      cursor: grabbing;
    }
    &.not-loaded {
      display: none;
    }
  }
  .number {
    cursor: pointer;
    text-align: center;
    padding: 0 0.25rem;
    &.active {
      border-left: 2px solid $primary-color;
    }
  }
  .numbers-container {
    min-width: 45px;
  }
}
