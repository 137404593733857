.card-choosen {
  width: 155x;
  margin: auto;

  .video-container {
    width: 155px;
    height: 250px;
    position: relative;
  }
  .card-video {
    width: 155px;
    height: 250px;
    object-fit: scale-down;
    cursor: grabbing;
    &.active {
      filter: blur(6px);
      -o-filter: blur(6px);
    }
    &.not-loaded {
      display: none;
    }
  }
  .delete-icon {
    background-color: #fff;
    border-radius: 50%;
    border: solid $red-color 3px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    color: $red-color;

    right: -10px;
    top: -16px;
    font-weight: 600;
    font-size: 1.25rem;
    cursor: pointer;
    z-index: 10;
    transition: $transition;
    &:hover {
      color: darken($red-color, 10%);
      border: solid darken($red-color, 10%) 3px;
    }
  }

  .mint-number {
    background-color: #c6b399;
    padding: 4px 4px;
    text-align: center;
    position: absolute;
    color: $background-color-1;
    border-radius: 4px;
    right: -8px;
    bottom: -10px;
    font-weight: 600;
    font-size: 0.75rem;
    cursor: pointer;
    z-index: 10;
    transition: $transition;
  }
}
