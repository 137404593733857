.dropdown {
  .dropdown-container {
    position: relative;
    width: 250px;
    .dropdown-btn {
      width: 100%;
      font-size: 1rem;
      text-align: center;
      text-decoration: none;
      border-radius: 4px;
      padding: 0.4rem 0.2rem;
      color: #333;
      cursor: pointer;
      background-color: #fff;
      transition: $transition;
      border: 1px solid $light-color;
      border-bottom: 2px solid $gray-color;
      &:hover {
        color: $primary-color;
      }
      &.error {
        border: 1px solid $red-color;
        border-bottom: 2px solid $red-color !important;
      }
      &.active {
        border-bottom: 2px solid $primary-color;
      }
    }

    ul {
      width: 100%;
      margin-top: 10px;
      position: absolute;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center;
      background: $light-color;
      border: 2px solid $border-color;
      left: 0px;
      list-style: none;
      border-radius: 5px;
      opacity: 0;
      pointer-events: none;
      transform: translateY(-10px);
      transition: all 0.4s ease;
      font-size: 1.1rem;
      background-color: #fff;
      &.active {
        z-index: 100;
        opacity: 1;
        pointer-events: all;
        transform: translateY(0px);
      }
    }

    li {
      width: 100%;
      padding: 0.5rem 0rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid $border-color;
      cursor: pointer;
      &:hover {
        background-color: lighten($primary-color, 30%);
      }
    }
    .fa-caret-down {
      margin-left: 0.5rem;
      transform: translateY(1px);
      pointer-events: none;
    }
  }
  &.gray-background {
    .dropdown-container {
      .dropdown-btn {
        background-color: $gray-color-2;
      }
    }
  }
}
