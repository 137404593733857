.buy-button {
  .buy-button-btn {
    box-sizing: border-box;
    appearance: none;
    border: 2px solid $card-green;
    border-radius: 8px;
    color: $card-green;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    padding: 0.5rem 1.4rem;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    transition: $transition;
    &:focus {
      color: #fff;
      outline: 0;
    }
    border-color: $card-green;
    color: #fff;
    box-shadow: 0 0 40px 40px $card-green inset, 0 0 0 0 $card-green;
    &:hover {
      background-color: transparent;
      box-shadow: 0 0 10px 0 $card-green inset, 0 0 10px 4px $card-green;
      color: #fff;
    }
  }
  &.btn-in-option {
    .buy-button-btn {
      width: 240px;
      padding: 0.5rem 0.025rem;
    }
  }
  &.size-big {
    .buy-button-btn {
      font-size: 22px;
    }
  }
}
