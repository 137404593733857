.switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .text {
    font-size: 1.2rem;
    transition: $transition;
    &.active {
      color: $primary-color;
      font-weight: 600;
    }
  }
  .switch-btn {
    max-width: 4.5rem;
    margin: 0 1rem;
    .tgl {
      display: none;
      &,
      &:after,
      &:before,
      & *,
      & *:after,
      & *:before,
      & + .tgl-btn {
        box-sizing: border-box;
        &::selection {
          background: none;
        }
      }

      + .tgl-btn {
        outline: 0;
        display: block;
        width: 4rem;
        height: 2rem;
        position: relative;
        cursor: pointer;
        user-select: none;
        &:after,
        &:before {
          position: relative;
          display: block;
          content: "";
          width: 50%;
          height: 100%;
        }

        &:after {
          left: 0;
        }

        &:before {
          display: none;
        }
      }

      &:checked + .tgl-btn:after {
        left: 50%;
      }
    }

    // themes
    .tgl-light {
      + .tgl-btn {
        background: darken(#f0f0f0, 10%);
        border-radius: 2em;
        padding: 2px;
        transition: all 0.4s ease;
        &:after {
          border-radius: 50%;
          background: #fff;
          transition: all 0.2s ease;
        }
      }

      &:checked + .tgl-btn {
        background: $primary-color;
      }
    }
  }
}
