.shared-input-title {
  font-weight: 600;
  font-size: 1.1rem;
  padding-bottom: 0.25rem;
}

.shared-hr {
  width: 42px;
  height: 4px;
  border-radius: 4px;
  color: $primary-color;
  border: none;
  background-color: $primary-color;
  margin-bottom: 0.4rem;
}

.shared-not-found {
  min-height: 120vh;
  background-color: $background-color;
}

.shared-blur-text {
  color: transparent !important;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.4) !important;
}
