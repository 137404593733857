.game-properties {
  padding: 0.75rem 0.25rem;
  border: solid 4px #fff;
  border-radius: 24px;
  &.silver {
    border: solid 4px #1f51ff;
    box-shadow: rgba(#1f51ff, 0.48) 3px 2px 8px 0px, rgba(#1f51ff, 0.8) -3px -1px 8px 0px;
  }
  &.gold {
    border: solid 4px #ffd700;
    box-shadow: rgba(#ffd700, 0.48) 6px 2px 16px 0px, rgba(#ffd700, 0.8) -6px -2px 16px 0px;
  }

  &.active {
    color: #fff;
    text-shadow: 0 0 3px $card-green, 0 0 7px $card-green;
    cursor: pointer;
    border: 4px solid $card-green;
    box-shadow: rgba($card-green, 0.48) 6px 2px 16px 0px, rgba($card-green, 0.8) -6px -2px 16px 0px;
  }

  .one-row {
    display: flex;
  }
  .one-col {
    width: 50%;
    text-align: center;
  }
  .one-col-last {
    width: 100%;
  }
  .img-property {
    width: 50px;
    height: 50px;
  }
  .img-container {
    width: 70px;
    height: 70px;
    margin: auto;
    &.active {
      border: solid 3px $red-color;
      border-radius: 12px;
    }
  }
}
