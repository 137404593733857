.dashboard-rules {
  .one-round {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 767.98px) {
      flex-direction: column;
      .result,
      .one-poss {
        padding: 1rem 0rem;
      }
      .result {
        padding-bottom: 4rem;
      }
    }
  }
  .img-container {
    width: 150px;
  }
  .img-property {
    width: 75px;
    height: auto;
  }
}
