.game-notification {
  background-color: $fourth-color;
  border-radius: $main-border-radius;
  box-shadow: $main-box-shadow;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 12px;
  text-shadow: 0 0 3px $fourth-color, 0 0 7px $fourth-color;
  cursor: pointer;
  border: 4px solid $fourth-color;
  box-shadow: rgba($fourth-color, 0.48) 6px 2px 16px 0px, rgba($fourth-color, 0.8) -6px -2px 16px 0px;

  .bell {
    font-size: 4rem;
  }

  .btn-noti {
    color: $fourth-color;
    cursor: pointer;
    background-color: #fff;
    font-weight: 600;
    &:hover {
      background-color: $gray-color-2;
    }
  }
}
