.dashboard-top-elements {
  display: flex;
  align-items: center;
  justify-content: space-around;
  .nordic-logo-img {
    width: 200px;
    height: auto;
  }
  .banner {
    overflow: hidden;
  }
  .banner-img {
    width: 600px;
    height: auto;
    transition: $transition;
    cursor: pointer;
    &:hover {
      transform: scale(1.015);
    }
  }

  @media (max-width: 1199.98px) {
    .banner,
    .you-own {
      display: none;
    }
  }
  @media (max-width: 767.98px) {
    .nordic-logo {
      display: none;
    }
  }
}
