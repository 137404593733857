.cookies {
  display: none;
  opacity: 0;
  transition: all 1s ease-out;
  display: block;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $third-color;
    min-height: 240px;
    padding: 0.75rem 12px;
    width: 290px;
    border: none;
    color: white;
    position: fixed;
    bottom: 30px;
    right: 30px;
    border-radius: 5px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    z-index: 10000;
    @media (max-width: 575.98px) {
      width: 100%;
      min-height: 150px;
      bottom: 0px;
      right: 0px;
    }
    @media (max-width: 400.98px) {
      min-height: 210px;
    }
  }
  .link {
    font-size: 1.1rem;
    &:hover {
      color: #333333;
    }
  }
  button {
    background-color: #000000;
    transition: $transition;
    &:hover {
      background-color: #333333;
    }
  }
  &.show {
    opacity: 1;
  }
}
