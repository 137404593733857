.points-horizontal {
  .one-point {
    font-family: $font-family-1;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .name {
    width: 60px;
    text-align: start;
  }
  .points {
    width: 45px;
  }

  .bar-wrap {
    padding: 2px;
    width: 110px;
    border-radius: 100px;
    background-color: #fff;
  }

  .bar {
    height: 12px;
    transition: width 0.15s ease-out;
    background-color: $card-green;
    border-radius: 100px;
  }

  &.points-horizontal--card-drag-drop {
    .one-point {
      font-family: $font-family-1;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.8rem;
      padding-bottom: 0.2rem;
    }
    .name {
      width: 45px;
      text-align: start;
    }
    .points {
      width: 40px;
      text-align: center;
    }

    .bar-wrap {
      padding: 1px;
      width: 70px;
      border-radius: 100px;

      background-color: #fff;
    }

    .bar {
      height: 12px;
      transition: width 0.15s ease-out;
      background-color: $card-green;
      border-radius: 100px;
    }
  }
  &.card-scroll--points {
    font-size: 16px;
  }
  &.points-horizontal--game-outcome {
    .one-point {
      font-family: $font-family-1;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.8rem;
      padding-bottom: 0.2rem;
    }
    .name {
      width: 50px;
      text-align: start;
    }
    .points {
      width: 50px;
      text-align: center;
    }

    .bar-wrap {
      padding: 1px;
      width: 90px;
      border-radius: 100px;

      background-color: #fff;
    }

    .bar {
      height: 12px;
      transition: width 0.15s ease-out;
      background-color: $card-green;
      border-radius: 100px;
    }
  }

  .highlight-point {
    .name {
      color: $red-color;
    }
    .number {
      color: $red-color;
    }

    .bar {
      background-color: $red-color;
    }
  }
}
