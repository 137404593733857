.full-screen-waiting {
  background-color: #111;
  display: flex;
  align-content: center;
  justify-content: center;

  min-height: 100vh;
  .waiting-loader {
    border: 5px solid transparent;
    border-radius: 50%;
    border-top: 5px solid $primary-color;
    width: 400px;
    height: 400px;
    margin: auto;
    animation: spin 2s linear infinite;
    position: relative;
    @media (max-width: 575.98px) {
      width: 200px;
      height: 200px;
    }
  }
  .waiting-text {
    animation: glow 4s alternate infinite;

    color: $primary-color;
    display: block;
    position: absolute;
    width: 100%;
    height: 50%;
    text-align: center;
    transform: translateY(100%);
    @media (max-width: 575.98px) {
      font-size: 0.75rem;
    }
  }

  @keyframes glow {
    0%,
    100% {
      filter: drop-shadow(0 0 0px $primary-color) drop-shadow(0 0 0px $primary-color);
    }
    50% {
      filter: drop-shadow(0 0 20px $primary-color) drop-shadow(0 0 60px $primary-color);
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
