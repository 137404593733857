$color-wax-wallet: #ff774a;
$color-anchor-wallet: #3650a2;
.login-options {
  .content-container {
    display: flex;
    align-items: center;
    padding-top: 1.75rem;
    justify-content: space-around;
    @media (max-width: 767.98px) {
      flex-direction: column;
    }
  }
  .wax-content {
    background-color: $color-wax-wallet;
    padding: 0.75rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    cursor: pointer;
    transition: $transition;
    &:hover {
      background-color: darken($color-wax-wallet, 5%);
    }
  }

  .img-wax {
    height: 25px;
    width: auto;
    transform: translateY(5px);
  }
  .wax-text {
    transform: translateY(1px);
  }

  .anchor-content {
    background-color: $color-anchor-wallet;
    padding: 0.75rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    cursor: pointer;
    transition: $transition;
    &:hover {
      background-color: darken($color-anchor-wallet, 5%);
    }
  }

  .img-anchor {
    height: 30px;
    width: auto;
    transform: translateY(5px);
  }
  .anchor-text {
    transform: translateY(1px);
  }
  .not-yet {
    cursor: pointer;
    transition: $transition;

    &:hover {
      color: $gray-color;
    }
  }
}
