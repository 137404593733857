.calendar {
  position: relative;
  $width: 100%;
  $max-width: 400px;
  width: $width;
  max-width: $max-width;
  .termin {
    width: $width;
    max-width: $max-width;
    border: 1px solid $light-color;
    border-bottom: 2px solid $gray-color;
    padding: 0.26rem 0.2rem;
    text-align: center;
    font-size: 1rem;
    cursor: pointer;
    transition: $transition;
    background-color: #fff;
    i {
      margin-left: 0.4rem;
    }
    &:hover {
      color: $primary-color;
      border-bottom: 2px solid $primary-color;
    }
    &.error {
      border: 1px solid $red-color;
      border-bottom: 2px solid $red-color;
    }
    &.active {
      border-bottom: 2px solid $primary-color;
    }
  }
  .pop-up-calendar {
    position: absolute;
    width: $width;
    max-width: $max-width;
    background-color: #fff;
    border: solid 1px $border-color;
    margin-top: -30px;
    opacity: 0;
    z-index: 100;
    pointer-events: none;
    font-size: 0.9rem;
    height: 340px;
    transition: all 0.4s ease-in;
    &.active {
      opacity: 1;
      transform: translateY(30px);
      pointer-events: all;
    }
  }
  .month {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 10px;
    text-align: center;
    background-color: #fff;
    color: $primary-color;
  }
  .current-month {
    font-size: 1.3rem;
    font-weight: 600;
  }
  .current-date {
    font-size: 1.3rem;
    font-weight: 600;
  }

  .weekdays {
    background-color: #fff;
    font-size: 1.2rem;
    color: #333;
    //   padding: 3px 0;
    display: flex;
  }

  .days {
    padding: 12px 0;
    display: flex;
    flex-wrap: wrap;
  }

  .weekdays div,
  .days div {
    text-align: center;
    width: 14.28%;
  }

  .days div {
    padding: 4px 0;
    margin-bottom: 5px;
    transition: all 0.4s;
    font-weight: 600;
    border-radius: 12px;
  }

  .prev_date {
    color: #999;
    pointer-events: none;
  }

  .today {
    background-color: darken($primary-color, 20%);
    color: #fff;
  }

  .choosen {
    background-color: $green-color;
    color: #fff;
  }

  .days div:hover {
    cursor: pointer;
    background-color: #dfe6e9;
  }

  .prev,
  .next {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.4s;
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  @media (max-width: 575.98px) {
    $max-width: unset;
    .pop-up-calendar,
    .termin {
      max-width: $max-width;
    }
  }
}
