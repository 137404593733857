.game-waiting {
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .social-links {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: all 0.25 ease-out;
    cursor: pointer;
  }

  .telegram-img {
    height: 60px;
    width: auto;
    transition: $transition;
    &:hover {
      opacity: 0.9;
    }
  }
  .discord-img {
    height: 70px;
    width: auto;
    transition: $transition;
    &:hover {
      opacity: 0.9;
    }
  }
  .btn-container {
    width: 100%;
    max-width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .waiting-text {
    .animation-container {
      position: relative;
      // overflow: hidden;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      margin: auto;
    }
    .seconds {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .art {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 200px;
      margin: auto;
      border-radius: 50%;
    }
    .drop {
      position: absolute;
      border-radius: 50%;
      border: 1px solid transparent;
      animation: drop-green 12s ease-in-out infinite;
      -moz-animation: drop-green 12s ease-in-out infinite;
      -webkit-animation: drop-green 12s ease-in-out infinite;
    }
    /*MULTI DROP*/

    .art .drop:nth-child(1) {
      animation-delay: -4.5s;
    }
    .art .drop:nth-child(2) {
      animation-delay: -1.5s;
    }
    .art .drop:nth-child(3) {
      animation-delay: 1.5s;
    }
    .art .drop:nth-child(4) {
      animation-delay: 3.5s;
    }
    .art .drop:nth-child(5) {
      animation-delay: 5s;
    }
    .art .drop:nth-child(6) {
      animation-delay: 7s;
    }
    .art .drop:nth-child(7) {
      animation-delay: 9s;
    }
    @keyframes drop-green {
      0% {
        width: 0px;
        height: 0px;
        box-shadow: 0px 0px 1px transparent, inset 0px 0px 1px transparent;
      }
      50% {
        box-shadow: 20px 20px 150px $primary-color, inset 20px 20px 150px $primary-color;
      }
      75% {
        box-shadow: 20px 20px 20px $primary-color, inset 20px 20px 20px $primary-color;
      }
      100% {
        width: 200px;
        height: 200px;
        opacity: 0;
        box-shadow: 0px 0px 0px $primary-color, inset 0px 0px 0px $primary-color;
      }
    }
  }
}
