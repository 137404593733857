.number {
  font-family: $font-family-1;
  color: #fff;
  transition: all 0.4s ease;
  &.silver {
    text-shadow: 0 0 3px #1f51ff, 0 0 7px #1f51ff;
  }
  &.gold {
    text-shadow: 0 0 3px #ffd700, 0 0 7px #ffd700;
  }
}
