.user-stats {
  color: #fff;
  font-family: $font-family-1;

  .color-green {
    color: $green-color;
  }
  .color-red {
    color: $red-color;
  }
  .one-el {
    display: flex;
  }
  .left-side {
    width: 140px;
  }
}
