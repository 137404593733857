.game-rounds {
  max-width: 240px;
  margin: auto;
  .table {
    width: 100%;
  }
  .result-td-head {
    font-size: 14px;
    width: 70px;
    text-align: center;
    height: 40px;
  }
  .round-td-head {
    font-size: 14px;
    text-align: center;
  }

  .result-td {
    font-size: 18px;
    width: 70px;
    text-align: center;
    &.lost {
      color: $red-color;
    }
    &.won {
      color: $card-green;
      font-weight: 600;
    }
  }
  .round-td {
    font-size: 18px;
    text-align: center;
    height: 42px;
  }
  .head-col {
    border: red solid;
    td {
      margin-bottom: 2rem !important;
    }
  }
}
