.input {
  width: 100%;

  input {
    outline: none;
    padding: 0.6rem 0.2rem;
    font-size: 1rem;
    background-color: #fff;
    width: 100%;
    border: none;
    border: 1px solid $light-color;
    border-bottom: 4px solid $primary-color;
    transition: $transition;
    font-family: $font-family-1;
    font-weight: 600;
    &::placeholder {
      color: $gray-color;
    }
    &:focus {
      border-bottom: 4px solid $primary-color;
    }
    &.error {
      border: 1px solid $red-color;
      border-bottom: 4px solid $red-color;
    }
  }
  &.gray-background {
    input {
      background-color: $gray-color-2;
    }
  }
}
