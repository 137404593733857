.dashboard-friend {
  .question-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .success-color {
    color: $card-green;
  }
  .announce-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .discord-img {
    height: 50px;
    cursor: pointer;
    transition: $transition;
    &:hover {
      opacity: 0.9;
    }
  }
}
