.textarea {
  width: 100%;

  textarea {
    outline: none;
    width: 100%;
    padding: 0.8rem 0.2rem;
    font-size: 1rem;
    width: 100%;
    background-color: #fff;
    border: none;
    border: 1px solid $light-color;
    border-bottom: 2px solid $gray-color;
    transition: $transition;
    min-height: 250px;
    resize: none;
    &::placeholder {
      color: $gray-color;
    }
    &:focus {
      border-bottom: 2px solid $primary-color;
    }
    &.error {
      border: 1px solid $red-color;
      border-bottom: 2px solid $red-color;
    }
  }
  &.gray-background {
    textarea {
      background-color: $gray-color-2;
    }
  }
}
