.card-game {
  width: 175px;
  margin: auto;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  &.no-pointer {
    cursor: default;
  }
  .video-container {
    width: 160px;
    height: 250px;
    position: relative;
  }
  .card-video {
    width: 160px;
    height: 250px;
    object-fit: scale-down;
    &.not-loaded {
      display: none;
    }
  }
  .is-chosen {
    width: 40px;
    height: 16px;
    background-color: #fff;
    margin: auto;
    border-radius: 18px;
    &.active {
      height: 16px;
      background-color: $card-green;
      text-shadow: 0 0 3px $card-green, 0 0 7px $card-green;
      cursor: pointer;

      box-shadow: rgba($card-green, 0.48) 6px 2px 16px 0px, rgba($card-green, 0.8) -6px -2px 16px 0px;
    }
  }
  &.active {
    padding: 0.75rem 0.5rem;
    border: solid 4px $red-color;
    border-radius: 24px;
    .is-chosen {
      background-color: $card-green;
    }
  }
  &.lost {
    .card-video {
      filter: blur(4px);
      -o-filter: blur(4px);
      opacity: 0.5;
    }
  }
  &.won {
    .card-video {
      opacity: 0.5;
    }
  }
  &.tie {
    .card-video {
      opacity: 0.5;
    }
  }
}
