.message {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding: 1rem;
  transform: translate(0%, -50px);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  pointer-events: none;
  opacity: 0;
  z-index: 10000;
  &.active {
    opacity: 1;

    pointer-events: all;
    transform: translate(0%, 0%);
  }
  .content {
    position: absolute;
    width: 100%;
    max-width: 750px;
    margin: auto;
    font-size: 1.2rem;
    border-radius: 4px;
    padding: 1rem;
    padding-bottom: 2rem;
    opacity: 0.98;
    color: #fff;
    max-height: 90%;
    cursor: default;
    overflow-y: auto;
    background-color: $background-color-1;
    border-radius: 12px;
  }

  .close {
    font-weight: 600;
    float: right;
    text-align: right;
    color: gray;
    cursor: pointer;
    transition: $transition;
    &:hover {
      color: $primary-color;
    }
  }
  .content-text {
    padding: 2rem 12px;
  }
  .btn_container {
    display: flex;
    padding-top: 1rem;
    align-items: center;
    justify-content: center;
  }

  span.red-text {
    color: $red-color !important;
    font-weight: 600;
  }

  button {
    font-size: 1.2rem;
    font-family: $font-family-1;
  }
  .transparent {
    color: #fff;
  }
  &.question {
    .content {
      max-width: 800px;
      text-align: center;
    }
  }
  @media (max-width: 450.98px) {
    .content {
      font-size: 1rem;
    }
    button {
      font-size: 1rem;
    }
  }
}

.overlay-message {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  height: 100%;
  min-width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -10;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  &.active {
    z-index: 500;
    opacity: 0.7;
    pointer-events: all;
  }
}
