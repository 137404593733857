.stamp {
  position: absolute;
  display: inline-block;
  bottom: 15px;
  right: 6px;
  font-family: sans-serif;
  transform: rotate(-7deg);
  font-family: $font-family-1;

  .stamp-text {
    font-weight: 600;
    padding: 6px 8px;
    font-size: 1.2rem;
    font-weight: 600;
    min-width: 90px;
    text-align: center;
  }
  &.lost {
    .stamp-text {
      border: 4px solid $red-color;
      color: $red-color;
    }
  }
  &.won {
    .stamp-text {
      border: 4px solid $card-green;
      color: $card-green;
    }
  }

  &.tie {
    .stamp-text {
      border: 4px solid #fff;
      color: #fff;
    }
  }
}
