.dashboard-message {
  .waiting {
    text-align: center;
    border: 4px solid rgb(68, 253, 105);
    padding: 0.5rem 0.75rem;
    border-radius: 18px;
    span {
      color: #fff;
      max-width: 1200px;
      margin: auto;

      text-transform: uppercase;

      background-image: linear-gradient(
          134deg,
          rgba(0, 0, 0, 0.04) 0%,
          rgba(0, 0, 0, 0.04) 41%,
          rgba(188, 188, 188, 0.04) 41%,
          rgba(188, 188, 188, 0.04) 100%
        ),
        linear-gradient(
          19deg,
          rgba(41, 41, 41, 0.04) 0%,
          rgba(41, 41, 41, 0.04) 46%,
          rgba(233, 233, 233, 0.04) 46%,
          rgba(233, 233, 233, 0.04) 100%
        ),
        linear-gradient(
          65deg,
          rgba(24, 24, 24, 0.04) 0%,
          rgba(24, 24, 24, 0.04) 97%,
          rgba(108, 108, 108, 0.04) 97%,
          rgba(108, 108, 108, 0.04) 100%
        ),
        linear-gradient(
          311deg,
          rgba(58, 58, 58, 0.04) 0%,
          rgba(58, 58, 58, 0.04) 79%,
          rgba(220, 220, 220, 0.04) 79%,
          rgba(220, 220, 220, 0.04) 100%
        ),
        linear-gradient(90deg, rgb(25, 147, 13), rgb(68, 253, 105));
      background-size: auto auto;
      background-clip: border-box;
      background-size: 200% auto;
      color: #fff;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: textclip 9s linear infinite;
      display: inline-block;
    }
  }

  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }
}
