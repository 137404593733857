.error-text {
  color: #fff;
  font-weight: 600;
  opacity: 0;
  transition: $transition;
  min-height: 1rem;
  &.active {
    opacity: 1;
    color: $red-color;
  }
}
