.back-to-top-btn {
  .back_btn {
    background-color: $third-color;
    height: 45px;
    width: 45px;
    border: none;
    color: white;
    position: fixed;
    bottom: 30px;
    right: 30px;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    transition: all 0.25s ease-out;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    z-index: 1000;
    display: none;
    &.active {
      display: flex;
    }
    &:hover {
      transform: scale(1.05) translateY(-5px);
    }
  }
}
