.card-showdown {
  width: 225px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  .card-name {
    font-size: 0.8rem;
  }

  .is-chosen {
    width: 40px;
    height: 16px;
    background-color: #fff;
    margin: auto;
    border-radius: 18px;
  }
  &.out {
    padding: 0.75rem 0.5rem;
    border: solid 4px $red-color;
    border-radius: 24px;
  }
  &.won {
    padding: 0.75rem 0.5rem;
    border: solid 4px $card-green;
    border-radius: 24px;
  }
  &.tie {
    padding: 0.75rem 0.5rem;
    border: solid 4px #fff;
    border-radius: 24px;
  }

  .video-container {
    width: 205px;
    height: 400px;
    position: relative;
  }

  .card-video {
    width: 205px;
    height: 320px;
    object-fit: scale-down;
    &.not-loaded {
      display: none;
    }
  }

  .mint-number {
    background-color: #c6b399;
    padding: 4px 4px;
    text-align: center;
    position: absolute;
    color: $background-color-1;
    border-radius: 4px;
    right: -2px;
    top: 50px;
    font-weight: 600;
    font-size: 0.75rem;
    cursor: pointer;
    z-index: 10;
    transition: $transition;
  }
}
