.game-transfer-now {
  .extra-background {
    position: fixed;
    top: 0;
    left: 0;
    transition: all 0.6s ease-in;
    min-height: 100vh;
    height: 100%;
    min-width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: -10;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $transition;
    &.active {
      z-index: 100000;
      opacity: 0.98;
    }
  }
  .content {
    box-shadow: 0 0 0.1rem #fff, 0 0 0.1rem #fff, 0 0 0.5rem #1f51ff, 0 0 0.1rem #1f51ff, 0 0 0.7rem #1f51ff,
      inset 0 0 0.4rem #1f51ff;
  }
}
