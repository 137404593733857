.buy-options {
  .content{
    max-width: 1000px;
  }
  @media (max-width: 800.98px) {
    .content{
      max-width: 500px;
    }
  }
 .buy-options-container {
    display: flex;
    align-items: center;
    padding-top: 1.75rem;

    justify-content: space-around;
    .one-el {
      padding-bottom: 2rem;
    }
 

    @media (max-width: 800.98px) {
      flex-direction: column;
      justify-content: center;
      .one-el {
        padding-bottom: 4rem;
      } 
      .content {
        max-width: 500px;
      }
    }
    .img-cont {
      height: 60px;
      cursor: pointer;
      margin: auto;
    }
    .img-buy {
      width: 200px;
      height: auto;
      margin: auto;
      text-align: center;
    }
    .atomic-img {
      transform: translateY(-6px);
    }
 }
}
