.game-showdown {
  min-height: 100vh;
  padding-top: 2rem;
  padding-bottom: 12rem;
  .showdonw-layout-container {
    max-width: 1800px;
    margin: auto;
    padding: 0 16px;
  }
  .nordic-logo-img {
    width: 200px;
    height: auto;
  }
  .game-name {
    border: 4px #fff solid;
    border-radius: 24px;
    padding: 0.5rem 0.75rem;
    width: 100%;
    max-width: 300px;
    margin: auto;
    text-align: center;
    font-weight: 600;
    color: #fff;
  }

  .oppenent-name {
    border: 2px #fff solid;
    border-radius: 8px;
    padding: 0.5rem 0.25rem;

    max-width: 325px;
    margin: auto;
    text-align: center;
    font-weight: 600;
    color: #fff;
  }
  .next-round {
    border: 4px #fff solid;
    border-radius: 24px;
    padding: 0.5rem 0.75rem;
    width: 100%;
    // max-width: 180px;
    margin: auto;
    text-align: center;
    font-weight: 600;
    color: #fff;
    transition: $transition;
    cursor: pointer;
    &:hover {
      border: 4px $card-green solid;
      color: $card-green;
    }
  }
}
