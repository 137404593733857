.stats-home {
  width: 100%;

  .main-boxes-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .boxes-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .one-box {
    background-color: $background-color-1;
    padding: 1rem 12px;
    width: 275px;
    max-width: 100%;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    min-height: 280px;
  }
  .one-el {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .part-1 {
      width: 60%;
      max-width: 200px;
    }
    .part-2 {
      width: 40%;
      text-align: end;
    }
  }
  .one-el-2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .part-1 {
      width: 60%;
    }
    .part-2 {
      width: 40%;
      text-align: end;
    }
  }
  .one-el-3 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .part-1 {
      width: 30%;
    }
    .part-2 {
      width: 70%;
      text-align: end;
    }
  }
  .one-line {
    height: 2px;
    width: 100%;
    background-color: $primary-color;
  }
  .see-more {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
