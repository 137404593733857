$timer-color-1: $primary-color;
$timer-color-3: $red-color;
.game-timer {
  .animation-container {
    position: relative;
    width: 150px;
    height: 150px;
    margin: auto;
    border-radius: 50%;
  }
  .seconds {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .art {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    border-radius: 50%;
    height: 150px;
  }
  .drop {
    position: absolute;
    border-radius: 50%;
    border: 1px solid transparent;

    &.drop-green {
      animation: drop-green 12s ease-in-out infinite;
      -moz-animation: drop-green 12s ease-in-out infinite;
      -webkit-animation: drop-green 12s ease-in-out infinite;
    }

    &.drop-red {
      animation: drop-red 6s ease-in-out infinite;
      -moz-animation: drop-red 6s ease-in-out infinite;
      -webkit-animation: drop-red 6s ease-in-out infinite;
    }
  }

  .drop:nth-child(1) {
    animation-delay: -4.5s;
  }
  .drop:nth-child(2) {
    animation-delay: -1.5s;
  }
  .drop:nth-child(3) {
    animation-delay: 1.5s;
  }
  .drop:nth-child(4) {
    animation-delay: 3.5s;
  }
  .drop:nth-child(5) {
    animation-delay: 5s;
  }
  .drop:nth-child(6) {
    animation-delay: 7s;
  }
  .drop:nth-child(7) {
    animation-delay: 9s;
  }
  @keyframes drop-green {
    0% {
      width: 0px;
      height: 0px;
      box-shadow: 0px 0px 1px transparent, inset 0px 0px 1px transparent;
    }
    50% {
      box-shadow: 20px 20px 150px $timer-color-1, inset 20px 20px 150px $timer-color-1;
    }
    75% {
      box-shadow: 20px 20px 20px $timer-color-1, inset 20px 20px 20px $timer-color-1;
    }
    100% {
      width: 200px;
      height: 200px;
      opacity: 0;
      box-shadow: 0px 0px 0px $timer-color-1, inset 0px 0px 0px $timer-color-1;
    }
  }

  @keyframes drop-red {
    0% {
      width: 0px;
      height: 0px;
      box-shadow: 0px 0px 1px, inset 0px 0px 1px transparent;
    }
    50% {
      box-shadow: 20px 20px 150px $timer-color-3, inset 20px 20px 150px $timer-color-3;
    }
    75% {
      box-shadow: 20px 20px 20px $timer-color-3, inset 20px 20px 20px $timer-color-3;
    }
    100% {
      width: 200px;
      height: 200px;
      opacity: 0;
      box-shadow: 0px 0px 0px $timer-color-3, inset 0px 0px 0px $timer-color-3;
    }
  }
}
