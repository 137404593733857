.game-layout {
  min-height: 100vh;
  padding-top: 2rem;
  padding-bottom: 12rem;
  .game-layout-container {
    max-width: 1800px;
    margin: auto;
    padding: 0 16px;
  }
}
