$open-card-color: #c6b399;
.card-drag-drop {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  .card-drag-drop-card {
    width: 155px;
    height: 250px;
    background-color: $open-card-color;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    flex-direction: column;
    padding: 1rem 0.5rem;
    transition: $transition;
    cursor: pointer;
    .card-icon {
      width: 80px;
      height: 120px;
      border: solid #fff 3px;
      border-radius: 18px;
    }
    &.active {
      background-color: darken($open-card-color, 20%);
      color: $primary-color;
      .card-icon {
        border: solid $primary-color 3px;
      }
      .card-text {
        color: $primary-color;
      }
    }
  }
}
